import * as React from "react";
import Layout from "../../components/layout";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import TopImage from "../../components/topImage";

const StutteringPage = () => {
  return (
    <Layout>
      <TopImage imageName="stuttering" />
      <Row>
        <Col>
          <h1 className="page-title mb-5">
            What is Stuttering (or Stammering)?
          </h1>

          <p>
            Stuttering or stammering is a speech impediment, which usually
            appears in variations and combinations of speech blocks and
            repetitions of syllables.
          </p>
          <p>
            The severity of stuttering varies from person to person. Some people
            who stutter only have issues in saying certain words, others have
            difficulties with almost every word they say.
          </p>
          <p>
            Stuttering is usually accompanied by various mitigation strategies
            which the people who stutter usually have developed over the years.
            These are word switches, filler words like "um, am" and the likes,
            changing the first sound of words or to avoid speaking at all.
          </p>
          <p>
            There is currently no proven cure for stuttering known. Various
            therapy ideas exists, they usually focus on changing the speech flow
            around the occurrences of stuttering.
          </p>
          <p>
            It is difficult to find a reliable cure of stuttering as the cause
            of stuttering have not been discovered yet. There are various cases
            in which the stuttering is caused by a measurable and visible brain
            damage. But in most cases, no physical causes behind a stutter can
            be observed. These are the cases, in which{" "}
            <Link to="/stutter-relief/training">our speech training</Link> will
            most likely lead to fluency.
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default StutteringPage;
